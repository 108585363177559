import VClients from '../client-page-component/ValuedClients';
import PClients from '../client-page-component/PartialClients';

export default function Client () {
    return (
        <>
            <VClients/>
            <PClients/>
        </>
    );
}