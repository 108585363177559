const data = [
    {
        img: 'CyberParkTowerOne.jpg',
        name: 'BPO Araneta - CyberPark Tower One',
        logo: 'megawide.jpg',
        desc: 'The first within the 8-hectare development of Araneta Center, this 30-storey building is made to accommodate 100,000 BPO agents.',
        desc2: 'The towers parking management system, surveillance monitoring and access security, particularly the 2 sets of 2-lane turnstiles with PWD gate and its walkthrough metal detector are installed and terminated by Alcon Philippines in 2016.'
    },
    {
        img: 'NEXTower.jpeg',
        name: 'NEXTower',
        logo: 'centuryproperties.jpg',
        desc: 'This tilting tower occupies a prominent place in Manila&sbquo;s resurgent business district of Makati offering spectacular views of the surrounding cityscape.',
        desc2: 'The unique tower&sbquo;s 7-laneturnstiles with automatic PWD gate, divided on all of its entrances, are installed and terminated by Alcon Philippines in 2018.'
    },
    {
        img: 'ZETATower.PNG',
        name: 'ZETA Tower',
        logo: 'robinsonlandcorporation.jpg',
        desc: 'A 21-storey Twin Office Tower in Bridgetowne, Quezon City catering to the high demand of Business Process Outsourcing companies. The building is connected to different retail, dining and service outlets.',
        desc2: ' Alcon Philippines has installed 5 lanes of swing gate turnstile with an automatic PWD gate at the lobby of this PEZA Accredited building in 2018.'
    },
    {
        img: 'TheCurveTower.jpg',
        name: 'The Curve Tower',
        logo: 'megawide.jpg',
        desc: 'Located along 32nd Avenue corner 3rd Avenue. The Curve is a 32-storey building that&sbquo;s shaping up to be one of Bonifacio Global Citys most iconic landmarks.',
        desc2: 'The all-glass tower&sbquo;s sustainability system, monitoring all of its field devices and main equipment including but not limited to lightings, gen-set and A/C, and a part of its access security, particularly the 3-lane turnstile with automatic PWD gate, is installed by Alcon Philippines in 2018.'
    }
];

export default data;