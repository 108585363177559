// Components
import Hero from '../solutions-page-component/Hero';
import Services from '../solutions-page-component/Services';

export default function Solutions () {
    return (
        <>
            <Hero/>
            <Services/>
        </>
    );
}