// importing components
import Hero from '../home-component/Hero';
import Solutions from '../home-component/Solutions';
import Clients from '../home-component/Clients';
import Partners from '../home-component/Partners';
import Join from '../home-component/Join';

export default function Home (props) {
    return (
        <>
          <Hero/>
          <Solutions card={props.card}/>
          <Clients/>
          <Join/>
          <Partners/>
        </>
    );
}