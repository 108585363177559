const data = [
    {
        name: 'Location',
        data: '71-A Agno Ext. Brgy. Tatalon Quezon City',
        icon: 'bi bi-geo-fill'
    },
    {
        name: 'Email',
        data: 'sales@alconph.com',
        icon: 'bi bi-envelope-at-fill',  
    },
    {
        name: 'Call',
        data: '+63.2.8805.4812',
        icon: 'bi bi-telephone-fill'
    }
]

export default data;