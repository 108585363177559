export default function Solutions (props) {
    return (
        <section id="solutions">
            <div className="container">
                <h1 className="text-center mb-0" style={{fontSize: '3rem', textTransform: 'uppercase'}}>Our Solutions</h1>
                <div className="row">
                    {props.card}
                </div>
            </div>
        </section>
    );
}