// About Components
import WhatWeDo from '../about-page-component/WhatWeDo';
import Abouts from '../about-page-component/Content';
import data from '../about-page-component/Datas/whatWeDo';

export default function About () {

    const str = data.map((item, index) => {
        return (
                <div className="card mb-3" key={index}>
                    <div className="card-header lead fw-bold">
                        {item.title}
                    </div>
                    <div className="card-body">
                        <p className="card-text">{item.desc}</p>
                    </div>
                </div>
        );
    });

    return (
        <>
            <WhatWeDo card={str}/>
            <Abouts/>
        </>
    );
}