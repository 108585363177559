export default function PartialClients() {
    return (
        <section id='partial-clients' className="my-5" style={{color: 'black'}} data-aos='fade-up'>
            <div className="container">
                <h1 className="f4">Partial List of Clients/Projects</h1>
                <div className="list-container">
                    <div>
                        <div className="row">
                            <div className="col-lg-4">
                            <h4 className="f4 mt-5">Access Control System</h4>
                               <ul>
                        <li>AB LANWARES INC.</li>
                        <li>ADVANCE CONTROL TECH., INC.</li>
                        <li>
                            AEONPRIME LAND DEVELOPMENT CORPORATION
                            <ul>
                                <li>POLARIS</li>
                            </ul>
                        </li>
                        <li>CITY GOVERNMENT OF VIGAN</li>
                        <li>CONTINENTAL TEMIC PHILS.</li>
                        <li>CORNERSTEEL SYSTEMS CORP.</li>
                        <li>CUSTOMER FRONTLINE SOLUTIONS</li>
                        <li>DON PIN DEV'T CORP.</li>
                        <li>ECO VERDE MANAGEMENT CORP.</li>
                        <li>HI-R SYSTEM</li>
                        <li>ICATS INC.</li>
                        <li>IRMO, INCORPORATED</li>
                        <li>ISS FACILITY SERVICES PHILS., INC.</li>
                        <li>ISLA LIPANA & CO.</li>
                        <li>
                            JUAN D. N. & SONS, INC.
                            <ul>
                                <li>ENTEC TOWER</li>
                            </ul>    
                        </li>
                        <li>JM ECOTECH SOLUTIONS CORP.</li>
                        <li>KC COMPUNET</li>
                        <li>KALAYAAN DEVELOPMENT INDUSTRIAL CORPORATION</li>
                        <li>KINETIC PHILIPPINES</li>
                        <li>LOGIC SOLUTIONS, INC.</li>
                        <li>MADRAL INDUSTRIAL CORPORATION</li>
                        <li>MAKATI SHANGRI-LA</li>
                        <li>MEGAWIDE CORPORATION</li>
                        <li>KALAYAAN DEVELOPMENT INDUSTRIAL CORPORATION</li>
                        <li>
                            KALAYAAN DEVELOPMENT INDUSTRIAL CORPORATION
                            <ul>
                                <li>BPO ARANETA BLDG.</li>
                                <li>MEGAWIDE HEAD OFFICE</li>
                            </ul>
                        </li>
                        <li>
                        MEGAWORLD CORPORATION
                        <ul>
                            <li>1800 EASTWOOD</li>
                            <li>1800 EASTWOOD</li>
                            <li>8 UPPER MCKINLEY</li>
                            <li>WORLD FINANCE PLAZA</li>
                            <li>THE WORLD CENTRE</li>
                        </ul>
                        </li>
                        <li>MOOG CONTROLS CORP.</li>
                        <li>MICRODATA</li>
                        <li>NET PACIFIC INC.</li>
                        <li>
                            PHASES ELECTRICAL CORP.
                            <ul>
                                <li>IHUB 9 BLDG.</li>
                                <li>IHUB 10 BLDG..</li>
                            </ul>
                        </li>
                        <li>PHILCOMSPEC INC.</li>
                        <li>PRIMARIUS</li>
                        <li>PWC RESOURCE PLUS</li>
                        <li>
                            ROBINSONS LAND CORP
                            <ul>
                                <li>ZETA TOWER</li>
                            </ul>
                        </li>
                        <li>
                            S-TECH LTD.
                            <ul>
                                <li>PARC ROYALE</li>
                                <li>PASIG CANIOGAN</li>
                                <li>PASO DE BLAS</li>
                                <li>PASONG TAMO PONTE</li>
                                <li>PICADILLY</li>
                                <li>PLARIDEL</li>
                                <li>QUEZON AVENUE</li>
                                <li>RUFINO</li>
                                <li>SALCEDO</li>
                                <li>SAMSON RD.</li>
                                <li>SAN JOSE</li>
                                <li>STA. MARIA</li>
                                <li>TACLOBAN</li>
                                <li>TACLOBAN CASH CENTER</li>
                                <li>TIMOG</li>
                                <li>VISAYAS AVE.</li>
                                <li>ZABARTE</li>
                            </ul>
                        </li>
                        <li>CENTRAL AFFIRMATIVE COMPANY INCORPORATED</li>
                        <li>CONTINENTAL TEMIC</li>
                        <li>CORNERSTEEL SYSTEM CORP.</li>
                        <li>COMMERCE AND INDUSTRY PLAZA</li>
                        <li>CONSOLIDATED ROBUST, INC.</li>
                        <li>DIGITAL PARADISE INC.</li>
                        <li>DILIMAN COMMERCIAL CENTER</li>
                        <li>DSM MANILA LLP</li>
                        <li>DTSI</li>
                        <li>EDSA SHANGRI-LA</li>
                        <li>FIRST RESIDENCES</li>
                        <li>HARBOUR CENTRE PORT TERMINALS INC.</li>
                        <li>ICATS INCORPORATED.</li>
                        <li>JOLLIBEE</li>
                        <li>LANDMARK TRINOMA</li>
                        <li>
                            LBC EXPRESS, INCORPORATED
                            <ul>
                                <li>BACOLOD</li>
                                <li>MIAG-AO</li>
                                <li>GAISANO CAPITAL OTON</li>
                                <li>CARCAR CEBU</li>
                                <li>CEBU MABOLO</li>
                                <li>GAISANO GRAND</li>
                                <li>GAISANO GRAND MALL</li>
                                <li>GAISANO GRAND LILOAN</li>
                                <li>GAISANO MACTAN</li>
                                <li>CEBU AC CORTEZ</li>
                            </ul>
                        </li>
                        <li>LEVERAGE WORKS, INC.</li>
                        <li>LOGIC SOLUTIONS, INC.</li>
                        <li>MAKATI SHANGRI-LA</li>
                        <li>STEFANINI PHILIPPINES, INC.</li>
                        <li>SUTHERLAND GLOBAL SERVICES PHILIPPINES
                            <ul>
                                <li>SUTHERLAND SHAW</li>
                                <li>SUTHERLAND TARLAC</li>
                            </ul>
                        </li>
                        <li>TECHNOLOGIES SPECIALIST, INC</li>
                        <li>TEMIC AUTOMOTIVE (PHILS). INC.</li>
                        <li>UNIMASTERS INC
                            <ul>
                                <li>MADISON 101</li>
                            </ul>
                        </li>
                        <li>8242 DEVELOPMENT CORP
                            <ul>
                                <li>NEXT TOWER</li>
                            </ul>
                        </li>
                        <li>DIGITAL PARADISE INC.</li>
                        <li>DILIMAN COMMERCIAL CENTER</li>
                        <li>DSM MANILA LLP</li>
                        <li>DTSI</li>
                        <li>EDSA SHANGRI-LA</li>
                    </ul> 
                            </div>
                            <div className="col-lg-4">
                                <h4 className="f4 mt-5">Building Management System</h4>
                                <ul>
                                    <li>
                                        IRMO INCORPORATED
                                        <ul>
                                            <li>THE CURVE TOWER</li>
                                        </ul>
                                    </li>
                                </ul>
                                <h4 className="f4">Fire Detection and Alarm System</h4>
                                <ul>
                                    <li>ACTIVE POWER ELECTRICAL INC.</li>
                                    <li>BESTANK</li>
                                    <li>CANON MARKETING PHIL. INC.</li>
                                    <li>CONTINENTAL TEMIC AUTOMOTIVE PHILIPPINES</li>
                                    <li>DIGITAL PARADISE INC.</li>
                                    <li>MANILA NORTH HARBOUR PORT, INCORPORATED</li>
                                    <li>URESHI TECHNOLOGIES INC.</li>
                                </ul>
                                <h4 className="f4 mt-4">PA/VA BGM System</h4>
                                <ul>
                                    <li>ASIAN TERMINALS INC.</li>
                                    <li>OPTIMUM EQUIPMENT MANAGEMENT & EXCHANGE</li>
                                    <li>ONE COMMERCE INT'L CORP.</li>
                                    <li>PHILIPPINE AIR FORCE</li>
                                    <li>PREMIERE</li>
                                    <li>RDC TECHNOLOGIES SOLUTIONS</li>
                                    <li>TRICOM SYSTEMS</li>
                                    <li>UNIMASTERS CONGLOMERATE</li>
                                    <li>WEAVETECH NETWORKS INC.</li>
                                    <li>WEBTEK PHILIPPINES</li>
                                </ul>
                                <h4 className="f4 mt-4">Parking Management System</h4>
                                <ul>
                                    <li>PHASES ELECTRICAL CORPORATION
                                        <ul>
                                            <li>BPO ARANETA BLDG.</li>
                                            <li>DOUBLE DRAGON PLAZA</li>
                                        </ul>
                                    </li>
                                    <li>MADRAL INDUSTRIAL CORPORATION</li>
                                    <li>MEGAWIDE CORPORATION
                                        <ul>
                                            <li>BATCHING PLANT</li>
                                            <li>PRECAST</li>
                                        </ul>
                                    </li>
                                    <li>MEGAWORLD CORPORATION
                                        <ul>
                                            <li>1800 EASTWOOD</li>
                                            <li>1880 EASTWOOD</li>
                                            <li>BELAGIO TOWER II</li>
                                            <li>BELAGIO TOWER III</li>
                                            <li>CITYPLACE BINONDO I</li>
                                            <li>E-COMMERCE PLAZA</li>
                                            <li>EASTWOOD LE GRAND I</li>
                                            <li>MANHATTAN HEIGHTS</li>
                                            <li>MANILA BAYSHORE I</li>
                                            <li>MANILA BAYSHORE II</li>
                                            <li>SAVOY HOTEL</li>
                                            <li>THE WORLD CENTRE</li>
                                            <li>UPTOWN PLACE I</li>
                                            <li>UPTOWN PLACE II</li>
                                            <li>UPTOWN PLACE III</li>
                                        </ul>
                                    </li>
                                    <li>MGS HOME DEPOT</li>
                                    <li>MPLACE COMMERCIAL BLDG.</li>
                                    <li>MPLACE RESIDENTIAL BLDG.</li>
                                    <li>ONG ORDOÑEZ AND ASSOCIATES</li>
                                    <li>PHASES ELECTRICAL CORPORATION
                                        <ul>
                                            <li>CYBERPARK TOWER I</li>
                                            <li>LANDMARK TRINOMA</li>
                                            <li>MPLACE TOWER I</li>
                                            <li>MPLACE TOWER II</li>
                                            <li>SM BLUE COMMERCIAL</li>
                                            <li>SM BLUE RESIDENCES</li>
                                            <li>STARMALL MOLINO</li>
                                            <li>TWINGATES BUILDING</li>
                                        </ul>
                                    </li>
                                    <li>PHILIPPINE BOBBIN CORP. IMPERIAL TOBACCO GROUP</li>
                                    <li>POURI MARKETING INC.</li>
                                    <li>SAN ISIDRO LABRADOR PARISH</li>
                                    <li>SM BLUE COMMERCIAL BLDG.</li>
                                    <li>SOUTH AFRICAN EMBASSY</li>
                                    <li>STEFANINI PHILIPPINES, INC.</li>
                                    <li>ST. ALEXANDRA ESTATES HOME OWNERS ASSOCIATION</li>
                                    <li>STARMALL MOLINO</li>
                                    <li>THE WORLD CENTRE</li>
                                    <li>TORRE VENEZIA SUITES</li>
                                    <li>TORRE CENTRAL</li>
                                    <li>TWINGATES TOWER</li>
                                    <li>UNIMASTER CONGLOMERATE
                                        <ul>
                                            <li>MADISON 101</li>
                                        </ul>
                                    </li>
                                    <li>XIPHOLD, INC.</li>
                                </ul>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="f4 mt-5">Surveillance System</h4>
                                <ul>
                                    <li>AB LANWARES & TECH. INC.</li>
                                    <li>ADVANI APPAREL</li>
                                    <li>ARMY SIGNAL REGIMENT, PHILIPPINE ARMY</li>
                                    <li>ASIA INT'L AUCTIONEERS INC.</li>
                                    <li>ASIA UNITED BANK
                                        <ul>
                                            <li>6TH AVE. CALOOCAN</li>
                                            <li>32ND BGC</li>
                                            <li>39TH BGC</li>
                                            <li>6TH AVE. - CALOOCAN</li>
                                            <li>7TH AVE. - CALOOCAN</li>
                                            <li>999 MALL</li>
                                            <li>ALABANG MUNTINLUPA</li>
                                            <li>ANNAPOLIS</li>
                                            <li>AURORA</li>
                                            <li>BIÑAN</li>
                                            <li>BLUMENTRITT</li>
                                            <li>CALAPAN</li>
                                            <li>CAMARIN</li>
                                            <li>CAUAYAN ISABELA</li>
                                            <li>CDO CARMEN</li>
                                            <li>CEBU F. GON.</li>
                                            <li>CEBU MANGO</li>
                                            <li>CONGRESSIONAL</li>
                                            <li>CUBAO</li>
                                            <li>DASMARIÑAS</li>
                                            <li>DAVAO - TAGUM BIZ DISTRCIT</li>
                                            <li>DAVAO JP LAUREL</li>
                                            <li>DIPOLOG</li>
                                            <li>DIVISORIA</li>
                                            <li>EDSA CASH CENTER</li>
                                            <li>ERMITA</li>
                                            <li>FONTANA</li>
                                            <li>GEN. LUIS CALOOCAN</li>
                                            <li>GENSAN APARENTE</li>
                                            <li>GUIGUINTO</li>
                                            <li>INTRAMUROST</li>
                                            <li>JN CENTER H.O</li>
                                            <li>LAGRO</li>
                                            <li>LEGASPI</li>
                                            <li>LUCENA</li>
                                            <li>MALABON GOV.</li>
                                            <li>MASANGKAY</li>
                                            <li>N. DOMINGO</li>
                                            <li>NAGA (BICOL)</li>
                                            <li>NOVALICHES</li>
                                            <li>OCEANAIRE</li>
                                            <li>ORTIGAS VAG</li>
                                        </ul>
                                    </li>
                                    <h4 className="f4 mt-4">Voice and Data Solutions</h4>
                                    <ul>
                                        <li>DIGITAL PARADISE INC.</li>
                                        <li>ICATS INC.</li>
                                        <li>UNIMASTERS CONGLOMERATE INCORPORATED
                                            <ul>
                                                <li>MADISON 101</li>
                                            </ul>
                                        </li>
                                        <h4 className="f4 mt-3">Wireless Broadband Solutions</h4>
                                        <li>ASIA INT'L AUCTIONEERS INC</li>
                                        <li>BRAID NETWORKS</li>
                                        <li>CERES ASIA MARKETING</li>
                                        <li>CIGNALE ENTERPRISE</li>
                                        <li>COENNOVATIONS INC</li>
                                        <li>DAKAK PARK AND BEACH RESORT</li>
                                        <li>DSM POWERLINE</li>
                                        <li>EARTH ASPIRE CORPORATION</li>
                                        <li>ELIZABETH SETON SCHOOL</li>
                                        <li>HARBOUR CENTRE PORT TERMINALS INC.</li>
                                        <li>ITSMART SYSTEM SOLUTIONS</li>
                                        <li>LOGIC SOLUTIONS, INC.</li>
                                        <li>MANHATTAN HEIGHTS</li>
                                        <li>MINERGY POWER CORPORATIONS</li>
                                        <li>NUEVA ECIJA UNIVERSITY OF SCIENCE AND TECH.</li>
                                        <li>UNIMASTERS CONGLOMERATE INCORPORATED
                                            <ul>
                                                <li>MADISON 101</li>
                                            </ul>
                                        </li>
                                        <h4 className="f4 mt-3">IBS Project</h4>
                                        <ul>
                                            <li>DITO Telecommunity
                                                <ul>
                                                    <li>SM STA. MESA</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}