import data from './Datas/data';

// img 
import img1 from './assets/about.jpg';
import mv from './assets/pexels-pixabay-262438.jpg';

export default function About () {

    const str = data.map((item, index) => {
        return (
            <p key={index}>{item.str}</p>
        );
    });

    return (
        <section id='About'>
            <div className="container" style={{overflow: 'hidden'}}>
                <h1 className='header-title mb-3'>ABOUT US</h1>
                <div className="row g-5 reverse" data-aos='fade-up'>
                    <div className="col-sm-12 col-lg-6">
                        <div className="postcard">
                            <p className='rounded'><span className='who-we-are'>WHO WE ARE</span><i className='italic-text' style={{marginLeft: '20px'}}>We are your partner towards having innovative and eﬃcient Facility Management, Fire Safety and Security Systems.</i></p>
                        </div>
                        <div className="postcard-stmt">
                            {str}
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 building-img-container">
                        <img src={img1} alt="" className='img-fluid logos-building rounded' />
                    </div>
                </div>
                <div className="row" style={{marginTop: '6rem'}} data-id='fade-up'>
                    <div className="col-lg-6 mb-3 building-img-container">
                        <img src={mv} alt="" className='img-fluid logos-building rounded' />
                    </div>
                    <div className="col-lg-6 flex">
                        <div className='mission text-center text-md-start'>
                            <p className='h2'>Mission</p>
                            <p>To provide eﬃcient and eﬀective Integrated solutions in building management, safety and security through engineering expertise and standard compliance.</p>
                            <p className='h2'>Vission</p>
                            <p>To be recognized as the leading system integration in facility management, ﬁre safety and security systems in the Philippines.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}