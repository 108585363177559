import joinTeam from './assets/images/pexels-fauxels-3184465.jpg'

export default function Join () {
    return (
        <section id='join'>
            <div className="container" data-aos='fade-up'>
                <div className="row">
                    <div className="col-lg-6 text-center text-sm-start str">
                        <h2>Join Alcon Philippines, and be part of our amazing and passionate team.</h2>
                        <p className='lead'>If you are interested in working for Alcon Philippines Technologies and Solutions Inc., send your resumes to hr@alconphils.com, and your profile will be screened by our employment officer and review if you are qualified for one of our open job positions.</p>
                    </div>
                    <div className="col-lg-6 building-img-container">
                        <img src={joinTeam} alt="" style={{width: '100%'}} className='img-fluid rounded logos-building'/>
                    </div>
                </div>
            </div>
        </section>
    );
}