import data from './Data/services';

export default function Services() {
    return (
        <section className="service">
            <div className="container" style={{overflow: "hidden"}}>
                {data.map((item, index) => {
                    return (
                        <div className={`postcard-container flex-column mb-5 ${index % 2 === 0 ? 'flex-lg-row' : 'flex-lg-row-reverse'}`} key={index} id={item.imgId} data-aos="fade-up">
                            <div className="col-lg-6 building-img-container">
                                <img src={require(`./assets/img/${item.img}`)} alt="" className='img-fluid rounded logos-building mb-0'/>
                            </div>
                            <div className="col-lg-6">
                                <h1 style={{fontFamily: "monospace"}}>{item.title}</h1>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}