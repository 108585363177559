import alcon_logo from './assets//Alcon.png'
import {Link, useLocation} from 'react-router-dom';

export default function Header(props) {

    const location = useLocation();
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top" id="nav">
            <div className="container">
                <Link to="/" className='navbar-brand'>
                    <img src={alcon_logo} alt="" style={{width: '8rem'}}/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav ms-auto text-center gap-3">
                        <Link to='/' id='home-link' className={`nav-links fs-normal ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
                        <Link to='/solutions' id='solutions-link' className={`nav-links fs-normal ${location.pathname === '/solutions' ? 'active' : ''}`}>Solutions</Link>
                        <Link to='/clients' id='clients-link' className={`nav-links fs-normal ${location.pathname === '/clients' ? 'active' : ''}`}>Clients</Link>
                        <Link to='/about' id='about-link' className={`nav-links fs-normal ${location.pathname === '/about' ? 'active' : ''}`}>About</Link>
                        <Link to='/contacts' id='contact-link' className={`nav-links fs-normal ${location.pathname === '/contacts' ? 'active' : ''}`}>Contact</Link>
                    </div>
                </div>
                {/* <ScrollToTop/> */}
            </div>
        </nav>
    );
}