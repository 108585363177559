const data = [
    {
        imgId: 'bms',
        img: 'Building-Management-System.png',
        title: 'BUILDING MANAGEMENT SYSTEM',
        desc: 'Successfully unify all of your control, monitoring, and operational requirements. Our unique solutions are based on providing results for your buildings to reduce operational costs, increase efficiency & effectiveness and prepare your building for the future and internet of things.'
    },
    {
        imgId: 'acs',
        img: 'Access-Control-System-scaled.jpg',
        title: 'ACCESS CONTROL SOLUTIONS',
        desc: 'Our product lines allow simple access control of visitors, employees and the general public throughout a site. With low maintenance costs, easy to expand Access control system, key less convenience, simplicity to set up and change configuration as required and extremely fast lock release.'
    },
    {
        imgId: 'ts',
        img: 'pexels-mart-production-7252569.jpg',
        title: 'TURNSTILE SOLUTION',
        desc: 'Alcon oﬀers safe, reliable, convenient and stylish Half & Full-Height Physical Access System which are available in many diﬀerent designs, colors and forms - suited for diﬀerent applications such as corporate buildings, industrial sites, research institutions, IT centers, banks, airports, sport stadiums and more'
    },
    {
        imgId: 'vms',
        img: 'pexels-cottonbro-studio-8465067.jpg',
        title: 'VISITOR MANAGEMENT SYSTEM',
        desc: 'To avoid a lot of interruptions and receptionists being stuck at their desks, visitor management system was developed to illuminate challenges associated to office productivity, where it can be used to track visitors and people who enters the buildings or offices in a formal way.'
    },
    {
        imgId: 'pms',
        img: 'pexels-jose-espinal-1000633.jpg',
        title: 'PARKING MANAGEMENT SYSTEM',
        desc: 'Designed to manage diﬀerent requirements necessary for professional management of parking areas, Alcons Parking Management System not only optimizes your parking programs but also delivers convenient solutions for drivers. Facilitate reservation, navigation and payment literally with the use of your fingertips.'
    },
    {
        imgId: 'bb',
        img: 'automatic-boom-barriers.jpg',
        title: 'BOOM BARRIER',
        desc: 'Conveniently easy yet controlled entry. That is what Alcon`s Barriers and Fences are for. All barriers and fences are developed and designed using high grade raw material in accordance with international quality standards.'
    },
    {
        imgId: 'pgs',
        img: 'CG00101-_Car-Park-Guidance.jpg',
        title: 'PARKING GUIDANCE SYSTEM',
        desc: 'Alcon`s Parking Guidance System incorporates traffic monitoring, communication, processing, and variable message sign automation to convey services. A system designed to relief drivers find available parking spaces by directing them to under-occupied parking slot.'
    },
    {
        imgId: 'fdas',
        img: 'fire_alarm_guide.jpg',
        title: 'FIRE DETECTION AND ALARM SYSTEM',
        desc: 'Achieve protection at any level with our state-of-the-art fire protection solutions. We have a complete line of detectors and other alarm components applicable to different businesses with different requirements. We believe that ownership of fire detection systems extends beyond the initial purchase of the products themselves. We therefore offer customers a wide range of services to ensure that their installations not only meet basic operational and safety requirements on day one, but will continue to do so throughout their lifetime.'
    },
    {
        imgId: 'scs',
        img: 'pexels-brett-sayles-4339335.jpg',
        title: 'STRUCTURED CABLING SYSTEM',
        desc: 'Alcon indulges complete cabling systems and associated hardware that implement a comprehensive telecommunications infrastructure for a wide range of applications, including providing telephone services or transmitting data over computer networks.'
    },
    {
        imgId: 'voisd',
        img: 'voice-over-ip-phone.png',
        title: 'VOICE OVER IP SYSTEM DESIGN',
        desc: 'Voice over Internet Protocol (also called VoIP, IP Telephony, Internet Telephony, and Broadband Phone) is the routing of voice  conversations over the internet or through any other IP-based network. In general, phone service via VoIP is a free or costs less than equivalent service from traditional sources but similar to alternative PSTN  (Public Switched Telephone Network) service providers. VoIP to VoIP phone calls are typically free, whilst VoIP to PSTN calls generally  costs much cheaper than PSTN to PSTN calls. We can provide you good quality and low price choices for your telecommunication cost cutting.'
    },
    {
        imgId: 'is',
        img: 'Akuvox-X7HD_83106110.jpg',
        title: 'INTERCOM SYSTEM',
        desc: 'An intercom is a two-way electronic communication device containing circuitry for sending and receiving audio and/or video transmissions. Alcon Philippines offers systems such as wireless, wired, video intercom, door and gate opening and closing, and a large variety of systems. This system can be used in buildings or clusters of buildings that operate independently of the public telephone network.'
    },
    {
        imgId: 'cs',
        img: 'pexels-miguel-á-padriñán-579471.jpg',
        title: 'CATV SYSTEM',
        desc: 'The distribution of signals, specifically via satellite and subsequent transmission over coaxial or fiber optic cables to nearby homes or service subscribing entities, is one of the systems Alcon provides.'
    },
    {
        imgId: 'has',
        img:'ARGSOL_June2020_Blog1_HomeAutomationSystem_SaltLakeCityUT_IMAGE.jpg',
        title: 'HOME AUTOMATION SYSTEM',
        desc: 'Having the capability to automatically and/or remotely manage systems and devices within a home in order to improve its security, comfort, and consumption. Alcon Philippines features a smart home that allows you to manage many functions of the house in a simple way, including lighting, energy management, heating, video intercom, and security.'
    },
    {
        imgId: 'eams',
        img: 'palert-plus-2.png',
        title: 'EARTHQUAKE ALERT AND MONITORING SYSTEM',
        desc: 'Recording data are important parameters for reassessing building safety and restoring capacity, including post-earthquake building assessments. At Alcon Philippines, we provide security alarm systems that can save countless lives, or at least minimize loss of life.'
    },
    {
        imgId: 'pabms',
        img: 'public-address-system.jpg',
        title: 'PUBLIC ADDRESS AND BACKGROUND MUSIC SYSTEM',
        desc: 'Voice Alarm is increasingly important in the safe management of buildings. A voice message informs occupants exactly what to do in an emergency and it is a long-established fact that people respond more quickly and are more likely to take the correct action during an evacuation if voice messages are used instead of tone sounders. Voice Alarm systems are not only used for public buildings with a high number of visitors, but are also used more and more for public address and entertainment systems.'
    },
    {
        imgId: 'ias',
        img: 'managed-intrusion-alarm-hero.jpg',
        title: 'INTRUSION ALARM SYSTEM',
        desc: 'Our systems use the latest technologies and range from intruder alarms, control panels and motion sensors to fully integrated intrusion and door control security installations. They also include environmental protection and life safety devices, and perimeter protection products including glass break, seismic and shock sensors'
    },
    {
        imgId: 'sc',
        img: 'pexels-scott-webb-430208.jpg',
        title: 'Surveillance Camera',
        desc: 'We offer a complete and wide variety of compatible DVR, NVR, Analog, Megapixel HD-SDI and Network Cameras to meet all application and budgets'
    }
];

export default data;