import img9 from './assets/images/supporters/akari.png'
import img10 from './assets/images/supporters/asenware.png'
import img12 from './assets/images/supporters/axess.png'
import img13 from './assets/images/supporters/BDC_BIG.svg' //belden
import img14 from './assets/images/supporters/BOSCHLTD.NS_BIG.png' //bosch
import img15 from './assets/images/supporters/btcino.png'
import img16 from './assets/images/supporters/camel.png'
import img17 from './assets/images/supporters/comelit.png'
import img18 from './assets/images/supporters/dahua.png'
import img19 from './assets/images/supporters/dashou.png'
import img20 from './assets/images/supporters/distech.svg'
import img21 from './assets/images/supporters/DOKA.SW_BIG.svg' //dormakaba
import img22 from './assets/images/supporters/dsppa.png'
import img24 from './assets/images/supporters/entrypass.png'
import img25 from './assets/images/supporters/garrison.png'
import img26 from './assets/images/supporters/012450.KS_BIG.svg' //hanwa
import img28 from './assets/images/supporters/hid.png'
import img29 from './assets/images/supporters/002415SZ_BIG.svg' //hikvision
import img30 from './assets/images/supporters/icpdas.png'
import img31 from './assets/images/supporters/kiddie-logo-padded-180x170.svg'
import img32 from './assets/images/supporters/magneticAuto.png'
import img33 from './assets/images/supporters/mircom.png'
import img36 from './assets/images/supporters/6752.T_BIG.svg' //panasonic
import img37 from './assets/images/supporters/parktron.png'
import img38 from './assets/images/supporters/partner6.png' //trueportal
import img39 from './assets/images/supporters/premiumline.png'
import img40 from './assets/images/supporters/protec.png'
import img41 from './assets/images/supporters/sanlien.png'
import img42 from './assets/images/supporters/soyal.png'
import img43 from './assets/images/supporters/truvision.png'
import img44 from './assets/images/supporters/wago.png'
import img45 from './assets/images/supporters/zkteco.png'
import img46 from './assets/images/supporters/zoomtech.png'

export default function Partners() {

    // const logo = logos.map((item, index) => {
    //     return (
    //         // console.log(item.imgName)
    //         <div className="col-md-3" key={index}>
    //             <img src={item.imgName} alt="" style={{width: '50px', height: '60px'}}/>
    //         </div>
    //     );
    // });

    return (
        <section id='partners'>
            <h1 className="text-center mb-5" style={{fontSize: '48px'}}>OUR PARTNERS</h1>
            <div className="container" data-aos='fade-up'>
                <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row gap-3">
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img31} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img32} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img33} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img20} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img21} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img36} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img37} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img38} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img39} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img9} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img10} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img12} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img13} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img14} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img15} alt=""/>
                            </div>
                            
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row gap-3">
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img16} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img17} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img18} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img19} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img22} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img24} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img25} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img26} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img28} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img29} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img30} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img46} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img41} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img42} alt=""/>
                                </div>
                                <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <img className='logos' src={img43} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                    <div className="carousel-item">
                        <div className="row gap-3">
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img45} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img44} alt=""/>
                            </div>
                            <div className="col text-center rounded" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                <img className='logos' src={img40} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev" style={{gray: 'black'}}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next" style={{gray: 'black'}}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>
            </div>
        </section>
    );
}