const data = [
    {
        img: 'client1.png'
    },
    {
        img: 'client2.png'
    },
    {
        img: 'client3.png'
    },
    {
        img: 'client4.png'
    },
    {
        img: 'client5.png'
    },
    {
        img: 'client6.png'
    },
    {
        img: 'client7.png'
    },
    {
        img: 'client8.png'
    },
    {
        img: 'client9.png'
    },
    {
        img: 'client10.png'
    },
    {
        img: 'client11.png'
    },
    {
        img: 'client12.png'
    },
    {
        img: 'client13.png'
    },
    {
        img: 'client14.png'
    },
    {
        img: 'client15.png'
    },
    {
        img: 'client16.png'
    },
    {
        img: 'client17.png'
    },
    {
        img: 'client18.png'
    },
    {
        img: 'client19.png'
    },
    {
        img: 'client20.png'
    },
    {
        img: 'client21.png'
    },
    {
        img: 'client22.png'
    },
    {
        img: 'client23.png'
    },
    {
        img: 'client24.png'
    },
    {
        img: 'client25.png'
    },
    {
        img: 'client26.png'
    },
    {
        img: 'client27.png'
    },
    {
        img: 'client28.png'
    },
    {
        img: 'client29.jpg'
    },
    {
        img: 'client30.jpg'
    },
    {
        img: 'client31.jpg'
    },
    {
        img: 'client32.png'
    },
    {
        img: 'client33.png'
    },
    {
        img: 'client34.jpg'
    },
    {
        img: 'client35.png'
    },
    {
        img: 'client36.jpg'
    },
    {
        img: 'client37.png'
    },
    {
        img: 'client38.jpg'
    },
    {
        img: 'client39.png'
    },
    {
        img: 'client40.jpg'
    },
    {
        img: 'client41.jpg'
    },
    {
        img: 'client42.png'
    },
    {
        img: 'client43.png'
    },
    {
        img: 'client44.png'
    },
    {
        img: 'client45.jpg'
    },
    {
        img: 'client46.jpg'
    },
    {
        img: 'client47.jpg'
    }
]

export default data;