import {Link} from 'react-router-dom';

export default function Clients () {
    return (
        <section id="clients">
            <div className="clients container" data-aos='fade-up'>
                <h1 className="mb-2 f2">Our Clients</h1>
                <p className="lead">We believe that the customers' perception is reality. We pride ourselves in not just making a sale but building relationships founded on trust and loyalty with our clients.</p>
                <Link to='/clients' className="btn btn-danger text-light rounded" id="btn-client" >View Our Clients<i className="bi bi-arrow-right ms-3"></i></Link>
            </div>
        </section>
    );
}