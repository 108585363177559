import alcon_logo from './assets/images/Alcon.png';
// import { useState } from 'react';

import {Link} from 'react-router-dom';

export default function Footer ({handleClick, copyText}) {

    // const [copy, setCopy] = useState("");

    // const copyContent = async () => {
    //     var copyText = document.getElementById("email").innerText;
    //     try { 
    //         await navigator.clipboard.writeText(copyText);
    //         setCopy("-check-fill");
    //     } catch (err) {
    //         console.error("Failed to copy: ", err);
    //     }
    // }

    return (
        <footer id='footer' className="mt-5">
                <div className="footer-header">
                    <p className="text-center me-2 h5">Get connected with us on social networks:</p>
                    <div className="socials h4">
                        <a href="https://www.facebook.com/alconphils/" rel='noreferrer' target='_blank' className="fb-link">
                            <i className="bi bi-facebook me-2"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/alcon-telecommunications-philippines/?originalSubdomain=ph" rel='noreferrer' target='_blank' className="linkedin-link">
                            <i className="bi bi-linkedin"></i>
                        </a>
                    </div>
                </div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3 text-lg-start text-center mb-5">
                        <img src={alcon_logo} alt="" />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-center footer-links mb-4">
                        <h3 className='mb-3'>LINKS</h3>
                        <div className="">
                            <Link to='/' className='nav-link fs-normal'>Home</Link>
                            <Link to='/solutions' className='nav-link fs-normal'>Solutions</Link>
                            <Link to='/clients' className='nav-link fs-normal'>Clients</Link>
                            <Link to='/about' className='nav-link fs-normal'>About</Link>
                            <Link to='/contacts' className='nav-link fs-normal'>Contact</Link>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-lg-start text-center mb-4">
                    <h3 className='mb-3'>CONTACTS</h3>
                        <div className="">
                            <div>
                                <p className="mb-2" href="#">
                                    Main Office
                                </p>
                                <a href="https://www.google.com/maps/place/71A+Agno+Ext,+Quezon+City,+1113+Metro+Manila/@14.6259672,121.0123739,3a,75y,353.63h,81.13t/data=!3m6!1e1!3m4!1sUMYJTBoLvAMhuGVZKg8ymQ!2e0!7i16384!8i8192!4m7!3m6!1s0x3397b63f8e0153d3:0xff85799fdd1a6d29!8m2!3d14.6261587!4d121.0123765!10e5!16s%2Fg%2F11f9x96tm9" rel='noreferrer' target='_blank' className='fs-normal address'>
                                    <address className='fs-normal'>71-A Agno Ext. Brgy. Tatalon Quezon City, Metro Manila Philippines</address>
                                </a>
                                <p>Tel: +63.2.8805.4812</p>
                            </div>
                            <div>
                                <p className="mb-2" href="#">Sales Office</p>
                                <a href="https://www.google.com/maps/place/Wrenchworks+Trading+%26+Plumbing+Services+(Fire+Protection,+Sprinkler+System,+Smoke+Detector,+Electrical)/@14.6200156,121.0023824,3a,90y,280.96h,94.56t/data=!3m6!1e1!3m4!1sxmr9pRvgZfmJohGbunu3cQ!2e0!7i16384!8i8192!4m16!1m9!3m8!1s0x3397b610f0b4e307:0x5462d1b34f5cca55!2s9a+Apo+St,+Quezon+City,+1114+Metro+Manila!3b1!8m2!3d14.619769!4d121.002337!10e5!16s%2Fg%2F11fkcvl654!3m5!1s0x3397b610f90863fd:0xf31a1b9e73791990!8m2!3d14.62003!4d121.002199!16s%2Fg%2F11b8_br4gf" className='fs-normal address' rel='noreferrer' target='_blank'>
                                    <address className='fs-normal'>9-A Apo Street, Brgy. Sta. Teresita, Quezon City, M.M. Philippines</address>
                                </a>
                                    <p>Tel: +63.2.8568.1868,<br/> +63.999.8888.328</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-lg-start text-center">
                        <p className='' id='email'>
                            <i className="bi bi-envelope-at-fill h3 me-2"></i>sales@alconph.com
                            <button className={`bi bi-clipboard${copyText} text-white copy-btn ms-2`} onClick={handleClick}></button>
                        </p>
                        <p className=''><i className="bi bi-hourglass-bottom h3 me-2"></i>Office Hours: 8:30AM to 5:30PM</p>
                        <p className=''><i className="bi bi-telephone-fill h3 me-2"></i>We do on call services for urgent and specific customer request and requirements</p>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p className='text-center'>Alcon Philippines 2023 | Privacy Policy</p>
            </div>
        </footer>
    );
}