const weDo = [
    {
        title: 'System Integration',
        desc: 'Having the creativity, analytical skills and communication skills, we integrate worthwhile solutions that allow our customers to make the most of their budget.  Our team ensures that all the systems we integrate completely works together and in harmony, to increase productivity and data consistency, resulting in smoother work flows, better process efficiency and happily having reduced error margins.'
    },
    {
        title: 'Project Management',
        desc: 'With the use of specific knowledge, latest technical skills, correct tools and methodologically techniques, our dedicated Project Management Team, makes sure that the project implementation is in accordance with the approved design, and that all are in full compliance with Engineering Standards and Time Line.  The team also ensures that the systems are fully functional and effective, most of the time exceeding our client’s expectations. '
    },
    {
        title: 'Engineering and Design',
        desc: 'We engage in understanding the demands of our clients’ businesses.  As we learn about their needs, we plan and strategize to come up with solutions which are tailored-fit to our customers’ requirements. We guarantee that the solutions which we offer are best fit, not just to our clients’ desideratum, but to local and international standards as well.  We develop innovative design solutions, evaluate options, conduct tests and implement solutions.'
    },
    {
        title: 'After Sales Service',
        desc: 'We constantly educate our valued clients with the new trends in the Safety Engineering Sphere, and share with them our insights. We extend our hands to provide support to our respected customers after the products and/or services have been purchased, which usually leads to high customer satisfaction, loyalty and word-of-mouth-marketing, maintaining the positive image and credibility of our team, and the whole company.'
    },
    {
        title: 'Preventive Maintenance Service',
        desc: 'We help business by improving the reliability of its installed devices and systems.  We perform regularly scheduled maintenance activities to help prevent unexpected failures in the future. Our team performs its best to help prolong the life of the equipment, reduces downtime decreases reactive maintenance requests, helping to manage compliance and inspection needs, and prevents costly emergency repairs from occurring.  '
    }
]

export default weDo;