import {useEffect, useState} from 'react';

//components
import Header from './components/Header/Header'
import About from './components/pages/About';
import Home from './components/pages/Home';
import Solutions from './components/pages/Solutions';
import Clients from './components/pages/Client';
import Contact from './components/pages/Contact';
import Button from './components/scrollToTopBtn/Button';
import ScrollToTops from './components/ScrollToTop';
import Footer from './components/home-component/Footer';

import './App.css';

import Data from './components/solutionsData'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Animate on scroll
import AOS from 'aos';
import 'aos/dist/aos.css';

import {Routes, Route, HashRouter} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function App() {

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    }
    else if (scrolled <= 500) {
      setVisible(false);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    }
    );
  };

  useEffect(() => {
    AOS.init({
      delay: 50, // values from 0 to 3000, with step 50ms
      duration: 200
    });
  }, []);

  const card = Data.map((item, index) => {
    return (
      <div className="col-sm-6 col-lg-3 g-5 fade-up" key={index} data-aos='fade-up'>
          <HashLink to={`/solutions/#${item.id}`} id='navigator'>
            <div className="card cards">
                <i className={`text-center ${item.icon} mb-3 h1 card--icon`} style={{color: '#9F000F'}}></i>
                <h6 className="card-title text-center">{item.title}</h6>
              <div className="card-body">
                  <p className="card-text text-center truncate">{item.description}</p>
              </div>
                  <button className='text-center read-more'>Read More &gt;&gt;</button>
            </div>
          </HashLink>
        </div>
      );
    });

    // useEffect(() => {
      // const element = document.querySelectorAll('.fade-up');

      // const observer = new IntersectionObserver(entries => {
      //   entries.forEach(entry => {
      //     entry.target.classList.toggle('show', entry.isIntersecting);
      //   });
      // },
      // {
      //   threshold: 0.5
      // })

      // element.forEach(elem => {
      //   observer.observe(elem);
      // });
    // }, []);

    window.addEventListener('scroll', toggleVisible);

    const [copy, setCopy] = useState("");

    const copyContent = async () => {
        var copyText = document.getElementById("email").innerText;
        try { 
            await navigator.clipboard.writeText(copyText);
            setCopy("-check-fill");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }

  return (
    <HashRouter>
      <Header/>
      <Button handleClick={scrollToTop} visible={visible}/>
      <ScrollToTops/> 
      <Routes>
        <Route path='/' element={<Home card={card}/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/solutions' element={<Solutions/>}></Route>
        <Route path='/clients' element={<Clients/>}></Route>
        <Route path='/contacts' element={<Contact handleClick={copyContent} copyText={copy}/>}></Route>
      </Routes>
      <Footer handleClick={copyContent} copyText={copy}/>
    </HashRouter>
  );
}

export default App;