import datas from './Data/Data';
// import {useState} from 'react';

export default function Content ({handleClick, copyText}) {

    // const [copy, setCopy] = useState("");

    // const copyContent = async () => {
    //     var copyText = document.getElementById("email").innerText;
    //     try { 
    //         await navigator.clipboard.writeText(copyText);
    //         setCopy("-check-fill");
    //     } catch (err) {
    //         console.error("Failed to copy: ", err);
    //     }
    // }

    const informations = datas.map((item, index) => {
        return (
            <div className="info" key={index} data-aos='fade-up'>
                <div className='d-flex'>
                    <i className={`h3 me-3 ${item.icon}`}></i>
                    <h2>{item.name}</h2>
                </div>
                <div className="contacts">
                    <p className='lead' id={`${item.name === 'Email' ? 'email' : ''}`}>{item.data}</p>
                    {item.name === 'Email' && <button className={`bi bi-clipboard${copyText} copy-btn ms-2`} onClick={handleClick}></button>}
                </div>
            </div>
        );
    });

    return (
        <section className="contact-page">
            <div className="container">
                <h1 className="header-title mb-4">CONTACT US</h1>
                <div className="map-container mb-3" data-aos='fade-up'>
                    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.5241208760913!2d121.01018781533404!3d14.626163880369687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b63f8e0153d3%3A0xff85799fdd1a6d29!2s71A%20Agno%20Ext%2C%20Quezon%20City%2C%201113%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1677736686170!5m2!1sen!2sph" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='infos'>
                    {informations}
                </div>
            </div>
        </section>
    );
}