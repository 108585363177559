const aboutUs = [
    {
        str: 'Alcon Philippines Technologies and Solutions Inc. is your “Total Solutions Provider”.  We offer high-quality products and different systems, together with excellent services that our valued clients truly deserve.'
    },
    {
        str: 'For several decades in the industry, Alcon has equipped itself with the right manpower, experience and expertise that can respond to the current trends and diverse requirements of its customers.  Team effort, sincerity and hard work have helped us guarantee timely deliveries of innovative and comprehensive solutions. These attributes and characteristics have created the foundation and culture of our company throughout the years.'
    },
    {
        str: 'We are the official and exclusive distributor of Alcon Technology Corporation, with a factory based in Taiwan, as well as branches and dealers worldwide such as USA, United Kingdom, Middle East and Southeast Asia.  It was 2013 when Alcon Philippines acquired its ownership and since then the company stood independently.  Whilst promoting Alcon Wireless Solutions, the team diversified to Electronic Low Voltage Systems involving Building Management, Fire Detection and Alarm, Access Control, CCTV, Voice and Data Networking, along with Integration of different systems, as we continuously engage with state-of-the-art technologies, aiming to put smiles on our clients’ faces on every project completion'
    }
];

export default aboutUs;