import {Link} from 'react-router-dom'
// import video from './assets/bg-video.mp4';

export default function Hero (props) {
    return (
        <section id="hero">
            <div className='d-flex flex-column align-items-center' data-aos="fade-up" style={{zIndex: "999"}}>
                <h3 className='text-center alcon'>Alcon Philippines</h3>
                <p className='text-center credential'>Customer centric service by integrating high quality solutions and reliable services.</p>
                <Link to='/solutions' id='btn-solutions' className='btn btn-danger rounded' style={{zIndex: '999'}}>Our Services<i className="bi bi-arrow-right mt-5 ms-3"></i></Link>
            </div>
        </section>
    );
}