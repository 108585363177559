import data from './Data/images';
import datas from './Data/partialClients';

export default function Clients() {

    const logos = data.map((item, index) => {
        return (
            <div key={index} className='text-center' data-aos='fade-up'>
                <img src={require(`./assets/client/${item.img}`)} alt="" className='img-fluid logos'/>
            </div>
        );
    });

    const building = datas.map((item, index) => {
        return (
            <div className="building mt-5" key={index} data-aos='fade-up'>
                <div className={`postcard-container flex-column ${index % 2 === 0 ? 'flex-lg-row' : 'flex-lg-row-reverse '}`}>
                    <div className="col-lg-6 building-img-container">
                        <img src={require(`./assets/buildings/${item.img}`)} alt="" className='img-fluid logos-building rounded mb-0 '/>
                    </div>
                    <div className="col-lg-6">
                        <div className="headings">
                            <img src={require(`./assets/buildings/${item.logo}`)} alt="" className='logos me-3 rounded'/>
                            <h4 className='mb-3'>{item.title}</h4>
                            <p className='description1'>{item.desc}</p>
                            <p className='description2'>{item.desc2}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section id='clients-page'>
            <div className="container">
                <h1 className="header-title text-sm-start">PARTIAL LIST OF OUR VALUED CLIENTS</h1>
                <div className="d-flex justify-content-center flex-wrap gap-5" style={{marginBottom: '10rem'}}>
                    {logos}
                </div>
                    {building}
            </div>
        </section>
    );
}